.strip {
    border: 1px solid rgb(207, 210, 214);
    /* border: 0.15rem solid #398FD1; */
    padding: 8px;
    margin: 8px;
    background-color: white;
}

.strip:hover {
    background-color: #e7e9ea;
}