.becode-assign-item__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.becode-assign-item__action i {
    margin-right: 1rem;
    margin-left: 1rem;
}
.becode-assign-item__tooltip {
    position: absolute;
    display: inline-block;
    border-bottom: 1px dotted black;
    height: fit-content;
}

.becode-assign-item__tooltip-text {
    overflow-y: scroll;
    visibility: hidden;
    width: fit-content;
    max-height: 200px;
    height: fit-content;
    background-color: #64b2d4;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    margin-top: 1rem;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.becode-assign-item__tooltip-text ul {
    padding-top: 0.2rem;
}

.becode-assign-item__tooltip-text ul, li {
    list-style: none;
    padding-left: 0.5rem;
    text-align: left;
    margin-right: 1rem;
    /*margin-left: 1rem;*/
}


.becode-assign-item__container:hover .becode-assign-item__tooltip-text {
    visibility: visible;
}