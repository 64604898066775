.result-item-title {
    font-weight: bold;
}

.result-item {
    white-space: pre-wrap;
}

.clipboard-copy {
    color: rgb(140, 140, 140);
}

.clipboard-copied {
    color: rgb(84, 171, 66);
}