.listbox__container {
    display: flex;
    flex-direction: column;
}

.listbox__title {
    height: 1.5rem;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.listbox__items {
    overflow-y: scroll;
}

.listbox__items > ul {
    padding-left:1rem;
    padding-right:1rem;
}

.listbox__items > ul > li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;
    padding-left:1rem;
    padding-right:1rem;
    border-top: #64b2d4 1px solid;
    cursor: pointer;
    user-select: none;
}
.listbox__items > ul > li:last-of-type  {
    border-bottom: #64b2d4 1px solid;
}
.listbox__items > ul > li.listbox__item--active {
    background-color:lightblue;
}