.simple-filter-section-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
}

.simple-filter-section-box > button {
    margin-left: 1em;
}

.simple-filter-section-box > a {
    margin-left: 1em;
}

.simple-filter-section-box > input {
    margin-left: 1em;
    font-size: 14px;
}

.checkboxCustom[type="checkbox"]{
    margin-top: 8px;
    font-size: 14px;
}