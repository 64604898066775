.selected-table-row {
  font-size: 14px;
  color: #282828;
  text-align: left;
  background-color: #f0f7fb !important;
  border: solid 0px #64b2d4;
  box-shadow: inset 0px 0px 0px 1px #64b2d4;
}

.details-container {
  display: flex;
  flex-direction: column;
}

.react-dual-listbox {
  display: flex;
}

.rdl-list-box {
  flex-grow: 1;
}

.rdl-filter,
.rdl-control {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.rdl-actions-right,
.rdl-actions-left {
  display: flex;
  flex-direction: column;
}

.rdl-actions {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
}

.rdl-control-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

.details-buttons {
  align-self: flex-end;
  margin-top: 5px;
}

.listbox {
  padding: 5px;
}

.gr-name-input {
  width: 100%;
}
.details-table {
  width: 100%;
  margin-bottom: 0px;
}

.gr-name-edition-title {
  width: 100px;
}

.delete-btn,
.new-group-button {
  margin-left: 5px;
}

#toast-container {
  top: 70px;
}

.header-button {
  margin-bottom: 5px;
}

.rt-resizable-header-content {
  height: 20px;
}

.error {
  border: 2px solid red !important;
}

input:focus {
  outline: none;
}

.controls {
  align-self: flex-end;
  justify-content: space-between;
}
